* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

.container {
  max-width: 1272px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 0 144px !important;
}

.page__wrapper {
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;

  padding-right: 0.75rem;
  padding-left: 0.75rem;

  position: relative;
}

.sectionTitle {
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  margin-bottom: 46px;
  font-weight: 400;
  font-size: 22px;
  color: #000;
  line-height: 25px;
}

.lightBtn {
  border: none;
  cursor: pointer;
  background-color: transparent;
  font-family: Arial, Helvetica, sans-serif;
  color: #666666;
  border: 1px solid #777777;
  transition: 0.3s all;
}

.lightBtn:hover {
  color: #fff;
  background: #cccccc;
  border: 1px solid #cccccc;
}

.mainBtn {
  border: none;

  cursor: pointer;
  background: #0aa349;
  color: #fff;
  transition: 0.3s all;
}

.mainBtn:hover {
  background: #088a3e;
}

.mainBtn:disabled {
  background-color: #e2e2e2;
}

.swiper-pagination-bullet-active {
  background: #0aa349 !important;
}

.mySection {
  margin-bottom: 60px;
}

@media (max-width: 1200px) {
  .container {
    max-width: 900px;
    width: 100%;
    padding: 0 !important;
  }

  .page__wrapper {
    width: 100%;
    padding: 0;
  }
}

@media (max-width: 992px) {
  .navigation__block {
    padding-left: 20px;
    cursor: pointer;
  }
  .page__wrapper {
    width: 100%;
  }

  .mySection {
    margin-bottom: 40px;
    padding: 0 20px;
  }
}

@media (max-width: 768px) {
  h1 {
    font-size: 20px;
  }

  .mySection {
    margin-bottom: 26px;
  }
}

@media (max-width: 540px) {
  h1 {
    font-size: 16px;
  }
}

.sectionTitle {
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  margin-bottom: 46px;
  font-weight: 400;
  font-size: 22px;
  color: #000;
  line-height: 25px;
}

.navigation__block {
  font-family: inherit;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #888888;
  cursor: pointer;
}

.loaders {
  display: grid;
  width: 100%;
  grid-template-columns: auto auto auto auto;
}
@media (max-width: 820px) {
  .loaders {
    grid-template-columns: auto auto;
  }
}

@media (max-width: 540px) {
  .loaders {
    grid-template-columns: auto;
  }
}
a {
  color: #888888;
}
.aboutHeader {
  font-size: 18px;
  margin-bottom: 15px;
}

.b24-form-btn {
  background: #0aa349 !important;
}

.b24-window-panel {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

.b24-window-close {
  background: #0aa349 !important;
}

.b24-window-scrollable {
  position: static !important;
}
