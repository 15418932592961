@import '../../../assests/styles/variables.scss';

@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

.header__wrapper {
  .logoImgContainer {
    width: 180px;
  }

  .logoImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
    margin-bottom: 30px;

    @media (max-width: 1200px) {
      padding: 15px 20px;
    }

    @media (max-width: 992px) {
      padding: 0 20px;
    }

    @media (max-width: 768px) {
      margin-bottom: 10px;
      justify-content: space-evenly;
    }
  }

  .header__logo {
    h1 {
      font-family: 'Lato', sans-serif;
      color: $fontBlack;
      font-size: 30px;

      span {
        color: $fontGreen;
      }
    }

    @media (max-width: 768px) {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 15px;

      h1 {
        font-size: 22px;
      }
    }
  }
}

.header__info {
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media (max-width: 768px) {
    flex-direction: row;
  }

  h2 {
    color: $fontBlack;
    font-family: $arialFont;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin-top: 5px;
    }

    @media (max-width: 768px) {
      font-size: 14px;
    }

    @media (max-width: 280px) {
      font-size: 10px;
    }
  }

  .callBtn {
    cursor: pointer;
    font-family: $arialFont;
    font-size: 14px;
    padding: 2px 14px;
    border: 1px solid $fontGreen;
    background-color: transparent;
    border-radius: 6px;

    @media (max-width: 768px) {
      display: none;
    }
  }
}

.header__searchBlock {
  display: flex;
  justify-content: space-between;
  gap: 40px;

  .header__search {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $fontGray;

    @media (max-width: 768px) {
      margin: 20px 0px;
    }

    input {
      border: none;
      outline: none;
      background-color: transparent;
    }
  }
}

.header__burgerMenu {
  display: none;
  cursor: pointer;
  position: absolute;
  left: 0;
  margin-left: 10px;
  top: 20px;

  @media (max-width: 768px) {
    display: block;
  }
}

.numHeader {
  font-size: 14px;
}

// .socials {
//   display: flex;
//   align-items: center;
//   gap: 5px;
//   margin-left: 15px;

//   a {
//     width: 22px;
//     height: 22px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     // border-radius: 2px;
//     // border: 1px solid $lineStrokesGray;
//     // padding: 3px;

//     &:visited {
//       color: $mainColor;
//     }
//     &:active {
//       color: $mainColor;
//     }

//     &:focus {
//       color: $mainColor;
//     }
//   }
// }

.socials {
  display: flex;
  align-items: center;
  gap: 15px;

  a {
    svg {
      width: 20px;
      height: 20px;
    }

    &:nth-child(2) {
      width: 15px;
    }

    display: flex;
    align-items: center;
    justify-content: center;
    // border-radius: 2px;
    // border: 1px solid $lineStrokesGray;

    &:active {
      color: $mainColor;
    }

    &:focus {
      color: $mainColor;
    }
  }
}