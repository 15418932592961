@import "../../../../../assests/styles/variables.scss";

.headerTop {
  background-color: $mainLightGray;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;

  @media (max-width: 768px) {
    display: none;
  }

  .headerTop__left {
    display: flex;
    gap: 5px;

    a {
      font-family: Arial, Helvetica, sans-serif;
      font-weight: 400;
      color: $fontGray;
      transition: 0.3s all;

      span {
        margin: 0 10px 0 10px;
      }

      &:hover,
      &:active,
      &:focus {
        color: $fontGreen;
      }

      &:first-child {
        span {
          display: none;
        }
      }

      @media (max-width: 860px) {
        font-size: 12px;
      }
    }
  }

  .headerTop__right {
    display: flex;
    gap: 18px;

    a {
      svg {
        width: 26px;
        height: 26px;
      }

      display: flex;
      align-items: center;
      justify-content: center;
      // border: 1px solid $lineStrokesGray;
      // border-radius: 2px;
      // padding: 5px;
    }

    a:hover {
      border: 1px solid $fontGreen;
      stroke: $fontGreen;
      fill: $fontGreen;
    }
  }
}