@import '../../../../../assests/styles/variables.scss';
.burger__parent.active {
}
.logoImgContainer {
  width: 180px;
}
.logoImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.overlay {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99999999999999999;
  background: rgba(255, 255, 255, 0.4);
}
.burger__parent {
  display: flex;
  flex-direction: column;
  position: absolute;
  align-items: center;
  justify-content: center;
  // top: 0;
  left: 2%;
  width: 95%;
  background-color: #fff;
  padding: 20px 0px;
  box-shadow: 0px 1px 3px 2px rgba(10, 163, 73, 0.3);
  margin: 0 auto;
  transition: left 0.3s ease-in-out;
  z-index: 1000;
  .header__burger {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;

    .header__burger__back {
      cursor: pointer;
      position: absolute;
      left: 0;
      margin-left: 25px;
    }

    .header__logo {
      h1 {
        font-family: 'Lato', sans-serif;
        color: $fontBlack;
        font-size: 22px;
        font-weight: 400;
        span {
          color: $fontGreen;
        }
      }
    }
    .header__cabinetBlock {
      display: flex;
      align-items: center;
      gap: 7px;
    }
  }

  .burger__menu {
    display: flex;
    flex-direction: column;

    justify-content: center;
    gap: 15px;
    width: 90%;
    .burger__search {
      display: flex;
      align-items: center;
      border-bottom: 1px solid $lineStrokesLight;
      width: 75%;
      justify-content: space-between;
      margin-bottom: 15px;
      padding: 3px;
      &:focus-within {
        border: 1px solid $lineStrokesLight;
      }

      input {
        border: none;
        width: 100%;
        font-size: 12px;
        color: $fontLightGray;
        line-height: 14px;
        padding: 3px;
        outline: none;
      }
    }
    .burger__menu__link {
      padding: 8px 0px;
      border-bottom: 1px solid $lineStrokesLight;
      a {
        color: $fontBlack;
        font-size: 14px;
        font-weight: 400;
        &:active {
          color: $fontGreen;
        }
        &:focus {
          color: $fontGreen;
        }
      }
    }
  }

  .contactInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
      display: flex;
      margin-bottom: 20px;
      align-items: center;
    }
    .socials {
      display: flex;
      align-items: center;
      gap: 15px;

      a {
        svg {
          width: 26px;
          height: 26px;
        }
        display: flex;
        align-items: center;
        justify-content: center;
        // border-radius: 2px;
        // border: 1px solid $lineStrokesGray;

        &:active {
          color: $mainColor;
        }
        &:focus {
          color: $mainColor;
        }
      }
    }
  }
}
