.link {
  position: fixed;
  bottom: 50px;
  right: 30px;
  z-index: 999999;

  @media (max-width: 768px) and (min-width: 425px) {
    bottom: 0;
    right: 14px;
    top: calc(200px + 46px);
    svg {
      width: 46px;
      height: 46px;
    }
  }
  @media (max-width: 540px) {
    bottom: 0;
    right: 14px;
    top: calc(200px + 30px);
    z-index: 50;
    position: fixed;
    svg {
      width: 30px;
      height: 30px;
    }
  }
}