// main colors

$mainColor: #0aa349;
$mainHoverColor: #088a3e;
$mainDark: #3c3c3c;
$mainYellow: #f0c60c;
$mainRed: #de3d43;
$mainGray: #e2e2e2;
$mainLightGray: #efefef;

// fonts color
$fontGreen: #0aa349;
$fontBlack: #222222;
$fontGray: #222222;
$fontLightGray: #888888;

// lines strokes colors

$lineStrokesDark: #777777;
$lineStrokesGray: #999999;
$lineStrokesLight: #cccccc;

//FONT
$arialFont: Arial, Helvetica, sans-serif;
