@import '../../../assests/styles/variables.scss';

.copyRight {
  background-color: $mainDark;
  width: 100%;
  color: $mainLightGray;
  padding: 16px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}
.main {
  margin-top: auto;
}
.list {
  padding: 40px 20px;
  background-color: $mainLightGray;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: auto auto;
    gap: 30px;
  }
}
.header {
  font-size: 14px;
  color: $fontBlack;
  font-weight: 400;
  line-height: 16.1px;
  margin-bottom: 16px;
}
.listComponents {
  li {
    a {
      color: $fontGray;
      line-height: 20px;
      font-weight: 400;
      cursor: pointer;
    }
    font-size: 14px;
    font-family: $arialFont;
    :hover {
      color: $fontGreen;
    }
  }
}
.inputContainer {
  border: 1px solid $lineStrokesDark;
  margin: 0;

  justify-content: space-between;
  display: flex;
  align-items: center;
  input {
    border: none;
    outline: none;
    background-color: transparent;
    padding: 12px 15px;
    @media (max-width: 768px) {
      width: 60%;
    }
  }

  button {
    cursor: pointer;
    border: none;
    border-left: 1px solid $lineStrokesDark;

    background-color: $mainLightGray;
    padding: 12px 15px;
  }
  button:hover {
    background-color: $lineStrokesLight;
  }
}

.socials {
  margin: 5px;
  border-radius: 2px;
  border: 1px solid $lineStrokesGray;
  padding: 6px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  :hover {
    stroke: $mainColor;
    fill: $mainColor;
  }
}

.socials:hover {
  border: 1px solid $mainColor;
}
.socialsContainer {
  display: flex;
  margin-top: 43px;
}
