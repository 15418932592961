.banner {
  width: calc((100vw - 984px) / 2);
  display: flex !important;
  justify-content: center;
  padding: 15px;
  height: 75%;

  @media (max-width: 1299px) {
    width: calc((100vw - 930px) / 2);
  }

  @media (max-width: 1100px) {
    display: none;
  }

  .banner__link {
    min-width: 144px !important;
    width: 144px !important;
    height: 100%;

    @media (max-width: 1200px) {
      width: 100px;
      height: 75%;
    }

    @media (max-width: 1100px) {
      display: none;
    }

    img {
      width: 100%;
      max-height: 100%;
      object-fit: contain;
    }

    @media (max-width: 500px) {
      display: none;
    }
  }
}

.banner__fixed__left {
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0px;

  margin-left: auto;
  margin-right: 0;
}

.banner__fixed__right {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;

  margin-right: auto;
  margin-left: 0;
}
