@import "./variables.scss";

* {
  font-family: Arial;

  font-weight: normal;
}
.header {
  color: $mainColor;
}
p {
  inline-size: 100%;
  overflow-wrap: break-word;
  word-break: break-all;
}
